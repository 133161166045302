import Component from '../core/Component';

export default class FileInput extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            input: null,
            label: null,
            fileWrap: null
        }

        this.defaultLabel = this.ref.label.innerHTML;
        this.defaultFileWrap = this.ref.fileWrap.innerHTML;
    }

    prepare() {
        this.ref.input.addEventListener('change', :: this.handleChange);
        this.ref.fileWrap.addEventListener('click', :: this.handleRemoveFile);
        this.parentElement = this.ref.label.closest('.form-Input-label')
    }

    handleChange(event) {
        if (event.target.files.length) {
            const file = event.target.files[0];
            this.ref.fileWrap.innerHTML = `<span class="Icon Icon--crossTiny"></span><b>${file.name.toLowerCase()}</b>`;
            this.parentElement.classList.add('form-Input-label--withFile')

        } else {
            this.parentElement.classList.remove('form-Input-label--withFile')
            this.ref.fileWrap.innerHTML = this.defaultFileWrap
            this.handleRemoveFile()
        }
    }

    handleRemoveFile(event) {
        event.preventDefault()
        event.stopPropagation();
        this.parentElement.classList.remove('form-Input-label--withFile')
        this.ref.fileWrap.innerHTML = this.defaultFileWrap
    }
}
