import Component from '../core/Component'
import EventBus from '../core/EventBus'

export const STATES = {
    ACTIVE: 'is-active'
}

export default class NavOpener extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick)

        EventBus.on('mobilenav:open', this.handleMobileNavOpen)
        EventBus.on('mobilenav:close', this.handleMobileNavClose)
    }

    destroy() {
        EventBus.off('mobilenav:open', this.handleMobileNavOpen)
        EventBus.off('mobilenav:close', this.handleMobileNavClose)
    }

    handleClick(event) {
        event.preventDefault()
        EventBus.emit('mobilenav:toggle')
    }

    handleMobileNavOpen = () => {
        this.element.classList.add(STATES.ACTIVE)
    }

    handleMobileNavClose = () => {
        this.element.classList.remove(STATES.ACTIVE)
    }

}