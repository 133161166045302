import Component from '../core/Component';
import enquire from 'enquire.js'
import { queries } from '../core/config'

export default class ModalOpener extends Component {
    constructor(element) {
        super(element)

        this.enquireHandler = {
            match: () => {
                this.mobile = true
            },
            unmatch: () => {
                this.mobile = false
            }
        }
    }

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
        enquire.register(queries.smallWideMax, this.enquireHandler)
    }

    destroy() {
        enquire.unregister(queries.smallWideMax, this.enquireHandler)
    }

    handleClick(event) {
        event.preventDefault();

        if (!this.mobile) {
            let id = this.element.getAttribute('href');

            if (!id) {
                return;
            }

            id = id.replace('#', '');

            const target = document.getElementById(id);

            if (!target) {
                return;
            }

            const modal = Component.getFromElement(target);

            if (modal) {
                modal.open();
            }
        } else {
            let url = this.element.dataset.mobileHref;
            window.open(url, '_blank')
        }
    }

}