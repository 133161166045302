import Component from '../core/Component'
import EventBus from '../core/EventBus'

export const STATES = {
    ACTIVE: 'is-active',
    SELECTED: 'is-selected',
}

export default class MainBanner extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            previous: null,
            next: null,
            previousImage: null,
            nextImage: null
        }

        this.current
    }

    prepare() {
        this.ref.previous.addEventListener("mouseenter", this.handleMouseEnter)
        this.ref.previous.addEventListener("mouseleave", this.handleMouseLeave)

        this.ref.next.addEventListener("mouseenter", this.handleMouseEnter)
        this.ref.next.addEventListener("mouseleave", this.handleMouseLeave)
    }

    handleMouseEnter = event => {
        if (event.target.dataset.ref == 'next') {
            this.ref.nextImage.classList.add(STATES.SELECTED)

            this.current = this.ref.nextImage
        } else {
            this.ref.previousImage.classList.add(STATES.SELECTED)

            this.current = this.ref.previousImage
        }

        this.element.classList.add(STATES.ACTIVE)
    }

    handleMouseLeave = event => {
        if (this.current) {
            this.current.classList.remove(STATES.SELECTED)
            this.current = null

            if (this.element.classList.contains(STATES.ACTIVE)) this.element.classList.remove(STATES.ACTIVE)
        }
    }
}