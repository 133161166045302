import Component from '../core/Component';
import { loadComponents } from '../core/componentUtils';
import EventBus from '../core/EventBus'

export default class ListPositionsCount extends Component {
    prepare() {
        EventBus.on('filter:updateList', data => {
            let fakeDom = document.createElement('div');
            fakeDom.innerHTML = data.content;
            let count = fakeDom.querySelectorAll('.jobs-List-item').length;
            this.element.innerHTML = count;
        });
    }

}