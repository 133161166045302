// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'

// project
import Bubble from './Bubble'
import BubbleOpener from './BubbleOpener'
import Carousel from './Carousel'
import DepartmentSticky from './DepartmentSticky'
import FileInput from './FileInput'
import FixedVideo from './FixedVideo'
import Filter from './Filter'
import Input from './Input'
import InfoSlider from './InfoSlider'
import ListPositions from './ListPositions'
import ListPositionsCount from './ListPositionsCount'
import MainBanner from './MainBanner'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import Nav from './Nav'
import NavOpener from './NavOpener'
import NextPage from './NextPage'
import ScrollTo from './ScrollTo'
import ShowMore from './ShowMore'
import YoutubeIframe from './YoutubeIframe'

const Components = {
    Antispam,
    // CookieBubble,
    Bubble,
    BubbleOpener,
    Carousel,
    DepartmentSticky,
    FileInput,
    FixedVideo,
    Filter,
    Input,
    InfoSlider,
    ListPositions,
    ListPositionsCount,
    MainBanner,
    Modal,
    ModalOpener,
    Nav,
    NavOpener,
    NextPage,
    ScrollTo,
    ShowMore,
    YoutubeIframe,
}

export default Components
