import Component from '../core/Component';
import EventBus from '../core/EventBus'
import YTPlayer from '../plugins/ytplayer'

export default class YoutubeIframe extends Component {
    constructor(element) {
        super(element);

        if (this.element.dataset.videoId) {
            this.videoID = this.element.dataset.videoId
        }
    }

    prepare() {
        this.player = new YTPlayer(this.element)
        this.player.load(this.videoID)

        EventBus.on('modal:close', ::this.pauseVideo)
    }

    playVideo = () => {
        this.player.play()
    }

    pauseVideo = () => {
        this.player.pause()
    }
}