import Component from '../core/Component'
import { toggleClass } from '../utils/dom'

export const STATES = {
    FOCUSED: 'is-focused',
    EMPTY: 'is-empty'
}

export const EMITS = {
    FOCUSED: 'focused',
    CHANGED: 'changed'
}

export default class Input extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            input: null,
            label: null
        }
    }

    prepare() {
        this.ref.input.addEventListener('focus', this.handleFocus)
        this.ref.input.addEventListener('blur', this.handleBlur)
        this.ref.input.addEventListener('keyup', this.handleKeyUp)
        this.ref.input.addEventListener('change', this.handleChange)

        this.check()
    }

    handleFocus = () => {
        this.element.classList.add(STATES.FOCUSED)
        this.emit(EMITS.FOCUSED)
    }

    handleBlur = () => {
        this.element.classList.remove(STATES.FOCUSED)
        this.check()
    }

    handleKeyUp = () => {
        this.check()
    }

    handleChange = () => {
        this.check()
    }

    check() {
        toggleClass(this.element, STATES.EMPTY, this.ref.input.value === '')
        this.emit(EMITS.CHANGED)
    }

    getInput() {
        return this.ref.input
    }

    getLabel() {
        return this.ref.label
    }
}
