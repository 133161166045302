import Component from '../core/Component'
import PositionObserver from '../meta/PositionObserver'
import Scroll from '../services/Scroll'
import enquire from 'enquire.js'
import { queries } from '../core/config'

const STATES = {
    HIDDEN: 'is-hidden',
    SCROLLED: 'has-scrolled',
    READY: 'is-ready'
}

const MARGIN = 40

export default class DepartmentSticky extends Component {
    constructor(element) {
        super(element)

        this.hasScrolled = false

        this.position = {
            x: 0,
            y: 0,
            initial: 0
        }
    }

    prepare() {
        this.element.classList.add(STATES.READY)
        Scroll.on('resize', this.handleResize)
        this.handleResize()

        if (!document.body.classList.contains('is-explorer')) {
            Scroll.on('scroll', this.handleScroll)
        }
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('scroll', this.handleResize)
    }

    pin(offset) {
        this.element.style.transform = "translate3d(0, " + (offset - this.position.initial + MARGIN) + "px, 0)"
        this.hasScrolled = true
        this.element.classList.add(STATES.SCROLLED)
    }

    unpin() {
        this.element.style.transform = "translate3d(0, 0, 0)"
        this.hasScrolled = false
        this.element.classList.remove(STATES.SCROLLED)
    }

    handleEnter() {
        this.element.classList.add(STATES.HIDDEN)
    }

    handleLeave() {
        this.element.classList.remove(STATES.HIDDEN)
    }

    handleResize = () => {
        if (!document.getElementById('departmentBanner')) {
            return
        }
        const scroll = Scroll.getScroll()

        this.bannerRect = document.getElementById('departmentBanner').getBoundingClientRect()
        this.windowHeight = window.innerHeight
        this.elementRect = this.element.getBoundingClientRect()
        this.position.initial = this.bannerRect.bottom + scroll.y - this.elementRect.height/2

        if (window.innerWidth > 1760) {
            this.position.x = this.bannerRect.left - this.elementRect.width/2
        } else if (window.innerWidth > 768) {
            this.position.x = 20
        } else {
            this.position.x = 0
        }

        this.element.style.top = this.position.initial + 'px'
        this.element.style.right = this.position.x + 'px'
    }

    handleScroll = ({offset}) => {
        this.scroll = offset.y + MARGIN

        if (this.scroll > this.position.initial) {
            this.pin(offset.y)
        } else {
            this.unpin()
        }
    }
}
