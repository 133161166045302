import Component from '../core/Component'
import Flickity from 'flickity'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const defaults = {
    cellSelector: '[data-ref="item"]',
    prevNextButtons: false,
    pageDots: false,
    contain: true,
    autoplay: false,
    lazyLoad: 2,
    responzive: true,
    resize: true,
    percentPosition: true
}

export const STATES = {
    READY: 'is-ready',
    DISABLED: 'is-disabled'
}

export default class Carousel extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            slider: null,
            items: [],
            prev: null,
            next: null
        }

        let options = {}

        if (this.element.dataset.options) {
            options = JSON.parse(this.element.dataset.options || '') || {}
        }

        this.options = {
            ...defaults,
            ...options,
        }

        this.enquireHandler = {
            match: () => {
                this.mobile = true
                this.flickity.options.draggable = false
                this.flickity.options.isDraggable = false
                this.flickity.isDraggable = false
            },
            unmatch: () => {
                this.mobile = false
                this.flickity.options.draggable = true
                this.flickity.options.isDraggable = true
                this.flickity.isDraggable = true
            }
        }

        this.flickity = null
        this.index = 0
    }

    prepare() {
        this.initSlider()
        enquire.register(queries.smallWideMax, this.enquireHandler)
    }

    destroy() {
        enquire.unregister(queries.smallWideMax, this.enquireHandler)
    }

    initSlider() {
        if (!this.flickity) {
            this.element.classList.add(STATES.READY)
            this.flickity = new Flickity(this.ref.slider, this.options)

            this.ref.prev.addEventListener('click', this.previous)
            this.ref.next.addEventListener('click', this.next)

            this.flickity.on('change', ::this.changeSlide );
        }
    }

    changeSlide(index) {
        if (index == 0) {
            this.ref.prev.classList.add(STATES.DISABLED)
        } else {
            this.ref.prev.classList.remove(STATES.DISABLED)
        }

        if (index >= this.flickity.slides.length-1) {
            this.ref.next.classList.add(STATES.DISABLED)
        } else {
            this.ref.next.classList.remove(STATES.DISABLED)
        }

        this.index = index
    }

    next = event => {
        event.preventDefault()
        this.flickity.next()
    }

    previous = event => {
        event.preventDefault()
        this.flickity.previous()
    }
}
