import Component from '../core/Component'


export default class BubbleOpener extends Component {

    prepare() {
        this.element.addEventListener('mouseenter', this.handleMouseEnter)
        this.element.addEventListener('mouseleave', this.handleMouseLeave)
        this.element.addEventListener('click', this.handleClick)
    }

    getBubble() {
        return Component.getFromElement(this.element.dataset.bubbleId || this.element.getAttribute('href').split('#')[1])
    }

    handleMouseLeave = () => {
        const bubble = this.getBubble()

        if (bubble) {
            bubble.close()
        }
    }

    handleMouseEnter = () => {
        const bubble = this.getBubble()

        if (bubble) {
            bubble.open(this.element)
        }
    }

    handleClick = event => {
        const bubble = this.getBubble()

        if (bubble) {
            event.preventDefault()
            bubble.open(this.element)
        }
    }
}
