import Component from '../core/Component';

export default class ShowMore extends Component {
    constructor(element) {
        super(element);

        this.active = false
    }

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
        window.addEventListener('resize', ::this.handleResize);
    }

    handleClick(event) {
        event.preventDefault()

        let eventTarget = event.target.closest('a').href
        let id = eventTarget.substring(eventTarget.indexOf('#')+1, eventTarget.length)
        this.target = document.getElementById(id)
        this.height = this.target.querySelector('[data-ref="text"]').getBoundingClientRect().height

        if (!this.active) {
            this.element.classList.add('is-active')
            this.target.style.height = this.height + 'px'
            this.active = true
        } else {
            this.element.classList.remove('is-active')
            this.target.style.height = 0
            this.active = false
        }

    }

    handleResize() {
        if (this.active) {
            this.height = this.target.querySelector('p[data-ref="text"]').getBoundingClientRect().height
            this.target.style.height = this.height + 'px'
        }
    }
}