import Component from '../core/Component';
import EventBus from '../core/EventBus'

export const STATES = {
    ACTIVE: 'is-active',
    SHOWN: 'is-shown',
}

export default class Filter extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            groups: [],
            buttons: [],
            checkboxs: [],
            inners: [],
            form: null,
            applied: null,
            appliedContent: null,
            deleteAll: null,
            toggle: null
        };

        this.active = null;
        this.page = 1
        this.hidden = true
    }

    prepare() {
        this.ref.inners.forEach(item => {
            item.addEventListener('click', event => {
                event.stopPropagation();
            });
        });

        this.ref.buttons.forEach((button, index) => {
            button.addEventListener('click', event => {
                event.preventDefault();
                event.stopPropagation();

                if (this.active != index) {
                    this.ref.groups.forEach(item => item.classList.remove('is-active'));
                    this.ref.groups[index].classList.add('is-active');
                    this.active = index;
                } else {
                    this.ref.groups.forEach(item => item.classList.remove('is-active'));
                    this.active = null;
                }
            });
        });

        this.ref.toggle.addEventListener("click", event => {
            event.preventDefault()

            if (this.hidden) {
                this.ref.toggle.classList.add(STATES.ACTIVE)
                this.ref.groups.map((group) => group.classList.add(STATES.SHOWN));
                this.hidden = false
            } else {
                this.ref.toggle.classList.remove(STATES.ACTIVE)
                this.ref.groups.map((group) => group.classList.remove(STATES.SHOWN));
                this.hidden = true
            }
        })

        this.ref.checkboxs.forEach((checkbox) => {
            checkbox.addEventListener('change', event => {
                event.preventDefault();
                event.stopPropagation();

                if (event.target.checked) {
                    let filtered = {
                        ...this.state.filtered,
                    };
                    filtered[event.target.name] = {
                        value: event.target.value,
                        id: event.target.name,
                        name: event.target.nextElementSibling.innerHTML,
                    };

                    checkbox.closest('.filter-Checkbox').classList.add(STATES.ACTIVE)

                    this.setState({
                        filtered: filtered,
                    });
                } else {
                    let filtered = { ...this.state.filtered };
                    filtered[event.target.name] = null;
                    checkbox.closest('.filter-Checkbox').classList.remove(STATES.ACTIVE)
                    delete filtered[event.target.name];
                    this.setState({
                        filtered: filtered
                    });
                }

                checkbox.closest('.filter-Item').classList.remove(STATES.ACTIVE)
                this.active = null;
            });
        });

        document.addEventListener('click', event => {
            this.ref.groups.forEach(item => item.classList.remove('is-active'));
            this.active = null;
        });

        this.ref.deleteAll.addEventListener('click', event => {
            this.ref.checkboxs.forEach(checkbox => checkbox.checked = false);
            let filtered = { ...this.state.filtered };
            Object.keys(filtered).forEach(key => {
                filtered[key] = null;
            });
            this.setState({
                filtered: filtered
            });
        });

        EventBus.on('filter:nextPage', () => {
            let filtered = {
                ...this.state.filtered,
            };

            this.page++

            filtered['page'] = {
                value: this.page,
                id: 'page',
                name: 'Page'
            };

            this.setState({
                filtered: filtered,
            });
        })
    }

    sendData(data) {
        var XHR = new XMLHttpRequest();
        var urlEncodedData = "";
        var urlEncodedDataPairs = [];
        var name;

        // Turn the data object into an array of URL-encoded key/value pairs.
        Object.keys(data).forEach(key => {
            if (this.state.filtered[key] != null) {
                urlEncodedDataPairs.push(encodeURIComponent(data[key].id) + '=' + encodeURIComponent(data[key].value));
            }
        });

        // Combine the pairs into a single string and replace all %-encoded spaces to
        // the '+' character; matches the behaviour of browser form submissions.
        urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

        // Define what happens on successful data submission
        XHR.addEventListener('load', function(event) {
            EventBus.emit('filter:updateList', { content: event.target.response });
        });

        // Define what happens in case of error
        XHR.addEventListener('error', function(event) {
            console.log(event)
        });

        // Set up our request
        XHR.open('POST', this.ref.form.action + '?' + urlEncodedData);

        // Add the required HTTP header for form data POST requests
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        let url = window.location.href
        let newUrl = url.substring(0, url.indexOf('?')) + '?' + urlEncodedData
        window.history.pushState("", "", newUrl);

        // Finally, send our data.
        XHR.send(urlEncodedData);
    }

    stateChange(state) {
        if ("filtered" in state) {
            this.sendData(this.state.filtered);
            let count = 0;

            if (Object.keys(this.state.filtered).length) {
                this.ref.applied.style.display = "";
                this.ref.appliedContent.innerHTML = "";

                Object.keys(this.state.filtered).forEach(key => {
                    console.log(this.state.filtered[key])
                    if (this.state.filtered[key] != null && this.state.filtered[key].id != 'page') {
                        count++;
                        let tag = document.createElement('span');
                        tag.className = 'filter-Applied-tag';
                        tag.innerHTML = this.state.filtered[key].name;

                        let button = document.createElement('button');
                        button.className = 'filter-Applied-remove';
                        button.innerHTML = "&times;";
                        button.dataset.id = key;
                        button.addEventListener('click', ::this.removeFromFiltered);

                        tag.appendChild(button);
                        this.ref.appliedContent.appendChild(tag);
                    }
                });
            }

            if (count === 0) {
                this.ref.applied.style.display = "none";
            }
        }
    }

    removeFromFiltered(event) {
        let filtered = { ...this.state.filtered };
        filtered[event.target.dataset.id] = null;
        //delete filtered[event.target.dataset.id];
        this.element.querySelector(`[name="${event.target.dataset.id}"]`).checked = false;
        this.setState({
            filtered: filtered
        });
    }

}