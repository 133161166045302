import Component from '../core/Component';
import { enableScrolling, disableScrolling } from '../services/Viewport/scroll';
import support from '../utils/BrowserSupport';
import keycode from 'keycode';
import EventBus from '../core/EventBus'

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
};

export default class Modal extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            modalBackground: null,
            modalLayer: null,
            modalContent: null,
            modalCloseButton: null,
        }

        this.handleKeyDown = ::this.handleKeyDown;
    }

    prepare() {
        this.ref.modalCloseButton.addEventListener('click', ::this.handleClick);
        this.ref.modalLayer.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATUS.ACTIVE)) {
                this.element.classList.remove(STATUS.VISIBLE);
            }
        });
        this.ref.modalLayer.addEventListener('click', ::this.handleClick);
        this.ref.modalContent.addEventListener('click', event => event.stopPropagation());
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleClick(event) {
        event.preventDefault();
        this.close();
    }

    handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close();
        }
    }

    open() {
        disableScrolling();
        this.element.classList.add(STATUS.VISIBLE);
        this.element.offsetWidth;
        this.element.classList.add(STATUS.ACTIVE);
        document.addEventListener('keydown', this.handleKeyDown);
        EventBus.emit('modal:open');
    }

    close() {
        enableScrolling();
        this.element.classList.remove(STATUS.ACTIVE);
        document.removeEventListener('keydown', this.handleKeyDown);
        EventBus.emit('modal:close');
    }
}