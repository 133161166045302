import Component from '../core/Component';
import Flickity from 'flickity-fade'
import { scrollToElement } from '../services/Scroll/index'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const defaults = {
    cellSelector: '[data-ref="item"]',
    prevNextButtons: false,
    pageDots: false,
    contain: true,
    autoplay: false,
    lazyLoad: 2,
    responzive: true,
    resize: true,
    percentPosition: true,
}

export const STATES = {
    READY: 'is-ready',
    ACTIVE: 'is-active',
    PREVIEW: 'is-preview',
}

export default class InfoSlider extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            links: [],
            items: [],
            indicator: null,
            slider: null
        }

        let options = {}

        if (this.element.dataset.options) {
            options = JSON.parse(this.element.dataset.options || '') || {}
        }

        this.options = {
            ...defaults,
            ...options,
        }

        this.enquireHandler = {
            match: () => {
                this.mobile = true
                this.flickity.options.draggable = false
                this.flickity.options.isDraggable = false
                this.flickity.isDraggable = false
            },
            unmatch: () => {
                this.mobile = false
                this.flickity.options.draggable = true
                this.flickity.options.isDraggable = true
                this.flickity.isDraggable = true
            }
        }

        this.mobile = false
    }

    prepare() {
        this.initSlider()
        window.addEventListener('resize', this.handleResize)
        enquire.register(queries.smallWideMax, this.enquireHandler)
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
        enquire.unregister(queries.smallWideMax, this.enquireHandler)
    }

    initSlider() {
        if (!this.flickity) {
            this.element.classList.add(STATES.READY)
            this.flickity = new Flickity(this.ref.slider, this.options)

            this.flickity.on('change', this.handleChange)

            this.ref.links.map(($element, index) => {
                $element.addEventListener('click', ::this.handleClick)

                if ($element.classList.contains(STATES.ACTIVE)) {
                    this.current = index
                }
            })
        }

        this.handleResize()
    }

    handleClick = event => {
        event.preventDefault()
        let index = this.ref.links.indexOf(event.target.closest('.infobox-Navigation-item'))
        this.flickity.select(index)
    }

    handleResize = () => {
        this.ref.links.map($element => {
            $element.positionY = $element.getBoundingClientRect().top + $element.getBoundingClientRect().height/2
        })

        this.elementRect = this.element.getBoundingClientRect()

        if (this.current != this.ref.links.length-1) {
            this.ref.indicator.style.height = (this.ref.links[this.current].positionY - this.elementRect.top) + 'px'
        } else {
            this.ref.indicator.style.height = '100%'
        }
    }

    handleChange = (index) => {
        if (index != this.ref.links.length-1) {
            this.ref.indicator.style.height = (this.ref.links[index].positionY - this.elementRect.top) + 'px'
        } else {
            this.ref.indicator.style.height = '100%'
        }

        if (this.mobile) {
            scrollToElement(this.element)
        }

        this.ref.links[this.current].classList.remove(STATES.ACTIVE)
        this.ref.links[index].classList.add(STATES.ACTIVE)

        this.current = index
    }
}