// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill';
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)

import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'

import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';
import './plugins/giant.show-if';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver';
new TouchMouseInputResolver();

import detectie from 'detectie';
import ScrollService from './services/Scroll'

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
const IE = detectie()

if (IE || iOS) {
    document.body.classList.add('is-explorer')
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import components from './components/';

window.app = {
    components: components
};

import { loadComponents, removeComponents } from './core/componentUtils'

loadComponents()
