import Component from '../core/Component';
import { loadComponents } from '../core/componentUtils';
import EventBus from '../core/EventBus'

export default class ListPositions extends Component {
    prepare() {
        EventBus.on('filter:updateList', data => {
            this.element.innerHTML = data.content;
            loadComponents(this.element);
        });
    }

}