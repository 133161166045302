import Component from '../core/Component'
import EventBus from '../core/EventBus'
import support from '../utils/BrowserSupport'
import { enableScrolling, disableScrolling } from '../services/Scroll'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}

export default class Nav extends Component {

    constructor(element) {
        super(element)

        this.isMobile = true

        this.enquireHandler = {
            match: () => {
                this.isMobile = false
                this.close()
            },
            unmatch: () => {
                this.isMobile = true
            }
        }
    }

    prepare() {
        EventBus.on('mobilenav:toggle', this.handleToggle)
        this.element.addEventListener('click', this.handleClick)

        this.element.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATES.ACTIVE)) {
                this.element.classList.remove(STATES.VISIBLE)
            }
        })

        enquire.register(queries.mediumUp, this.enquireHandler)
    }

    destroy() {
        EventBus.off('mobilenav:toggle', this.handleToggle)

        enquire.unregister(queries.mediumUp, this.enquireHandler)
    }

    handleToggle = () => {
        this.toggle()
    }

    handleClick = event => {
        if (this.isMobile && event.target.closest('a')) {
            this.close()
        }
    }

    toggle() {
        if (this.is(STATES.ACTIVE)) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        disableScrolling()
        this.element.classList.add(STATES.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATES.ACTIVE)
        EventBus.emit('mobilenav:open')
    }

    close() {
        this.element.classList.remove(STATES.ACTIVE)
        enableScrolling()
        EventBus.emit('mobilenav:close')
    }

}