import Component from '../core/Component'
import EventBus from '../core/EventBus'

export default class NextPage extends Component {
    prepare() {
        this.element.addEventListener('click', ::this.handleClick)
    }

    handleClick(event) {
        event.preventDefault()
        EventBus.emit('filter:nextPage');
    }
}