import Component from '../core/Component'
import Scroll from '../services/Scroll'
import enquire from 'enquire.js'
import { queries } from '../core/config'

const MARGIN = 40
const STATES = {
    READY: 'is-ready',
    PLAYING: 'is-playing'
}

export default class FixedVideo extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            video: null,
            control: null
        }

        this.stops = {
            top: 0,
            bottom: 0
        }

        this.startElement = document.getElementById('videoStart')
        this.stopElement = document.getElementById('videoEnd')

        this.playing = false

        this.enquireHandler = {
            match: () => {
                console.log(this.startElement)
                this.stopElement = this.startElement
            },
            unmatch: () => {
                console.log(document.getElementById('videoEnd'))
                this.stopElement = document.getElementById('videoEnd')
            }
        }
    }

    prepare() {
        this.element.classList.add(STATES.READY)

        if (!document.body.classList.contains('is-explorer')) {
            Scroll.on('scroll', this.handleScroll)
        }

        Scroll.on('resize', this.handleResize)

        enquire.register(queries.largeMax, this.enquireHandler)
        this.handleResize()

        this.element.addEventListener('click', ::this.handleClick)
        this.ref.video.addEventListener('play', ::this.play)
        this.ref.video.addEventListener('pause', ::this.stop)
        this.ref.video.addEventListener('seeking', ::this.play)
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('scroll', this.handleResize)

        enquire.unregister(queries.largeMax, this.enquireHandler)
    }

    pin(offset) {
        this.element.style.transform = "translate3d(0, " + (offset - this.stops.top + MARGIN) + "px, 0)"
        this.hasScrolled = true
    }

    unpin(offset) {
        this.element.style.transform = "translate3d(0, " + (offset) + "px, 0)"
        this.hasScrolled = false
    }

    handleResize = () => {
        if (!document.getElementById('videoStart') || !document.getElementById('videoEnd')) {
            return
        }

        const scroll = Scroll.getScroll()

        this.stops.top = this.startElement.getBoundingClientRect().top + scroll.y
        this.stops.bottom = this.stopElement.getBoundingClientRect().bottom + scroll.y

        this.elementRect = this.element.getBoundingClientRect()
    }

    handleScroll = ({offset}) => {
        this.scroll = offset.y + MARGIN

        if (this.scroll > this.stops.top && this.scroll + this.elementRect.height < this.stops.bottom) {
            this.pin(offset.y)
        } else {
            if (this.scroll + this.elementRect.height > this.stops.bottom) {
                this.unpin(this.stops.bottom - this.elementRect.height - this.stops.top)
            } else {
                this.unpin(0)
            }
        }
    }

    handleClick(event) {
        event.preventDefault()

        if (!this.playing) {
            this.play()
        } else {
            this.stop()
        }
    }

    play() {
        this.element.classList.add(STATES.PLAYING)
        this.ref.video.play()
        this.ref.video.controls = true
        this.playing = true
    }

    stop() {
        this.element.classList.remove(STATES.PLAYING)
        this.ref.video.pause()
        this.ref.video.controls = false
        this.playing = false
    }
}