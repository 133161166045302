import Component from '../core/Component'
import keycode from 'keycode'
import {queries} from '../core/config'
import support from '../utils/BrowserSupport'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    FULLSCREEN: 'is-fullscreen',
    FLIPPED: 'is-flipped'
}

const maxMobileBubbleArea = 50000

export default class Bubble extends Component {
    constructor(element, options = {}) {
        super(element, options)

        this.ref = {
            content: null,
            layer: null,
            arrow: null,
            close: null
        }

        this.openerElement = null
        this.contentBox = null

        this.timer = null
    }

    prepare() {
        this.ref.close.addEventListener('click', this.handleCloseClick)
        this.element.addEventListener('mouseleave', this.handleMouseLeave)
        this.element.addEventListener('mouseenter', this.handleMouseEnter)
        this.element.addEventListener(support.transitionEnd, event => {
            if (event.target === this.element && !this.is(STATES.VISIBLE)) {
                this.element.classList.remove(STATES.ACTIVE)
            }
        })
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown)
        document.removeEventListener('click', this.handleOuterClick)
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleMouseEnter = () => {
        clearTimeout(this.timer)
    }

    handleMouseLeave = () => {
        this.close()
    }

    handleCloseClick = () => {
        this.close()
    }

    handleScroll = () => {
        this.close()
    }

    handleOuterClick = event => {
        if (!this.ref.layer.contains(event.target)) {
            this.close()
        }
    }

    handleKeyDown = event => {
        if (keycode(event.keyCode) === 'esc' && this.is(STATES.ACTIVE)) {
            this.close()
        }
    }

    handleResize = () => {
        this.check()
    }

    open(element = null) {
        clearTimeout(this.timer)
        this.element.classList.remove(STATES.FULLSCREEN)
        this.element.classList.add(STATES.ACTIVE)
        this.openerElement = element
        this.check()
        this.element.classList.add(STATES.VISIBLE)

        setTimeout(() => {
            document.addEventListener('click', this.handleOuterClick)
        }, 0);
        document.addEventListener('keydown', this.handleKeyDown)
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleScroll)
    }

    check() {
        const contentBox = this.ref.content.getBoundingClientRect()

        if (window.matchMedia(queries.smallWideMax).matches && contentBox.width * contentBox.height > maxMobileBubbleArea) {
            this.element.classList.add(STATES.FULLSCREEN)
        } else {
            this.element.classList.remove(STATES.FULLSCREEN)
        }

        this.calculatePosition(this.openerElement)
    }

    calculatePosition(element = null) {
        if (!element) {
            return
        }

        this.element.classList.add(STATES.ACTIVE)

        const box = element.getBoundingClientRect()
        const parentBox = this.element.parentNode.getBoundingClientRect()
        const contentBox = this.ref.content.getBoundingClientRect()

        let top = box.top - parentBox.top
        let left = box.left - parentBox.left + box.width/2
        const centerLeft = left

        if (left + contentBox.width > window.innerWidth - 20) {
            left = left - contentBox.width
            this.element.classList.add(STATES.FLIPPED)
        }

    /*     if (left - contentBox.width/2 + parentBox.left < 10) {
            left = contentBox.width/2 - parentBox.left + 10
        }

        if (left + contentBox.width/2 + parentBox.left > window.innerWidth) {
            left = window.innerWidth - contentBox.width/2 - 10 - parentBox.left
        } */

        this.ref.layer.style.top = `${top}px`
        this.ref.layer.style.left = `${left}px`

        // this.ref.arrow.style.transform = `translateX(${centerLeft - left}px)`
    }

    close() {
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            this.element.classList.remove(STATES.FULLSCREEN)
            this.element.classList.remove(STATES.VISIBLE)
            document.removeEventListener('keydown', this.handleKeyDown)
            document.removeEventListener('click', this.handleOuterClick)
            window.removeEventListener('resize', this.handleResize)
            window.removeEventListener('scroll', this.handleScroll)
        }, 100)
    }

    toggle(element = null) {
        if (this.is(STATES.ACTIVE)) {
            this.close()
        } else {
            this.open(element)
        }
    }

}